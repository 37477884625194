<template>
    <div class="flex-1 com-upload" >
        <comUploadPc v-if="isPc" :file-list.sync="fileList" :Length="Length" />
        <template v-if="!isPc">
            <draggable v-if="Length!=1" style="width:100%" v-model="list" filter=".forbid" animation="300">
                <transition-group class="flex flex-wrap">
                <div class="item" v-for="(item, index) in fileList" :key="item">
                    <van-image
                    @click="preview(index)"
                    width="100%"
                    height="100%"
                    fit="cover"
                    :src="item+'?x-oss-process=style/compress'"
                    />
                    <!-- <div v-if="isEdit" @click.stop="del(index)" @mousemove.prevent="" @touchmove.prevent class="icon">
                        
                    </div> -->
                    <!-- <img v-show="isEdit" class="icon" @click.stop="del(index)" src="../assets/icon-del.png" alt=""> -->
                </div>
                </transition-group>
            </draggable>
        <div v-if="Length==1" class="flex flex-wrap" style="width:100%">
            <div class="item" v-for="(item, index) in fileList" :key="item">
                    <van-image
                    @click="preview(index)"
                    width="100%"
                    height="100%"
                    fit="cover"
                    :src="item+'?x-oss-process=style/compress'"
                    />
                    <!-- <div v-if="isEdit" @click.stop="del(index)" @mousemove.prevent="" @touchmove.prevent class="icon">
                        
                    </div> -->

                    <div @click.stop="del(index)" class="icon">
                    </div>
                    <!-- <img class="icon" @click.stop="del(index)" src="../assets/icon-del.png" alt=""> -->
                </div>
        </div>
        <div class="btn-box flex">
            <div class="btn1" v-show="fileList.length<Length" @click="uploadClick">
                <van-icon name="photo" color="#fff" size="18" />
                添加
            </div>
            <template v-if="Length!=1">
                <div class="btn2" v-show="fileList.length>0" @click="isMove=!isMove,isEdit=isMove?false:isEdit">
                    <van-icon name="exchange" color="#6E8DFF" size="18" />
                    {{isMove?'确定':'移动'}}
                </div>
                <div class="btn3" v-show="fileList.length>0" @click="isEdit=!isEdit,isMove=isEdit?false:isMove">
                    <van-icon name="edit" color="#FF3E4C" size="18" />
                    {{isEdit?'确定':'删除'}}
                </div>
            </template>
            <!-- <van-image
                v-show="fileList.length<Length"
                @click="uploadClick"
                width="100%"
                height="100%"
                fit="cover"
                :src="require('../assets/upload.png')"
                /> -->
        </div>
        <div v-if="!isMove" class="notEdit flex flex-wrap">
            <div class="item" @click="preview(index)" v-for="(item, index) in fileList" :key="index">
                <div v-if="isEdit" @click.stop="del(index)" @mousemove.prevent="" @touchmove.prevent class="icon">
                </div>
            </div>
        </div>
      </template>
    </div>
</template>

<script>
import { ImagePreview, Icon } from 'vant';
import wx from "weixin-js-sdk";
import { wxMedia } from "../api";
import { Image } from "vant";
import comUploadPc from "./comUpload_pc";

import draggable from "vuedraggable"
export default {
    name: "ComUpload",
    props: {
        fileList: {
            type: Array,
            default: () => {
                return [];
            },
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        Length: {
            type: Number,
            default: 100,
        },
    },
    watch: {
        list(val) {
            this.$emit('update:fileList', val)
        },
        fileList (val) {
            this.list = val
        }
    },
    computed: {
        
    },
    components: {
      [Image.name]: Image,
      [Icon.name]: Icon,
      draggable,
      comUploadPc
    },
    data() {
        return {
            uploadFile: [],
            list: this.fileList,
            isEdit: false,
            isMove: false,
            isWeixin: false,
            isPc: false,
        };
    },
    created() {
        //平台、设备和操作系统 
        var system ={ 
            win : false, 
            mac : false, 
            xll : false 
        };

        //检测平台 
        var p = navigator.platform; 
        system.win = p.indexOf("Win") == 0; 
        system.mac = p.indexOf("Mac") == 0; 
        system.x11 = (p == "X11") || (p.indexOf("Linux") == 0); 
        if(system.win||system.mac||system.xll){//转向后台登陆页面 
            this.isPc = true
        }
        // var ua = navigator.userAgent.toLowerCase()
        // this.isWeixin = ua.indexOf('micromessenger') != -1;
    },
    methods: {
        preview(index) {
          ImagePreview({
            images: this.fileList,
            startPosition: index
          })
        },
        del(index) {
          this.fileList.splice(index, 1)
          this.$emit('update:fileList', this.fileList)
        },
        uploadClick() {
            var that = this
            wx.chooseImage({
                count: this.Length==1?1:9, // 默认9
                sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
                success: function (res) {
                    var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                    that.uploadFun(localIds)
                },
            });
        },
        dataURLtoBlob (dataURI) {
          var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0] // mime类型
          var byteString = atob(dataURI.split(',')[1]) // base64 解码
          var arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
          var intArray = new Uint8Array(arrayBuffer) // 创建视图
        
          for (var i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i)
          }
          return new Blob([intArray], { type: mimeString })
        },
        uploadFun(localIds) {
            // this.$toast.loading({
            //     message: "上传中",
            //     forbidClick: true,
            //     duration: 0,
            // });
            var that = this
            var serverIds = []
            let localIdLength = localIds.length; //本地ID列表的长度
            let uploadCount = 0 // 已上传图片数量
            const upload = function() {
                console.log('数量'+uploadCount);
                wx.uploadImage({
                    localId: localIds[uploadCount], // 需要上传的图片的本地ID，由chooseImage接口获得
                    isShowProgressTips: 1, // 默认为1，显示进度提示
                    success: function (res) {
                        var serverId = res.serverId; // 返回图片的服务器端ID
                        serverIds.push(serverId)
                        uploadCount++
                        if (uploadCount==localIdLength) {
                            wxMedia({
                                mediaIds: serverIds.join(',')
                            }).then(res=>{
                                var fileList = that.fileList?that.fileList:[]
                                fileList = fileList.concat(res.data.data)
                                that.$emit('update:fileList', fileList)
                                console.log(fileList);
                            }).finally(()=>{
                                that.$toast.clear()
                            })
                        } else {
                            upload()
                        }
                        
                    }
                })
            }
            upload()
        },
    },
};
</script>
<style scoped>
.com-upload{
    position: relative;
    display: flex;
    flex-wrap: wrap;
}
.com-upload .item{
  position: relative;
  width: 30%;
  height: 0;
  padding-bottom: 30%;
  margin-right: 2%;
  margin-bottom: 3%;
}
.com-upload .item .icon{
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  /* pointer-events:auto; */
  z-index: 10;
  background-image: url(../assets/icon-del.png);
  background-size: 20px 20px;
  background-position: 100% 0;
  background-repeat: no-repeat;
  /* transform: translateZ(1px); */
}
.com-upload .item>>>.van-image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.com-upload>>>.van-icon{
    margin-right: 5px;
    margin-left: -10px;
}
.com-upload .btn1{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 126px;
    height: 34px;
    background: linear-gradient(to bottom, #7fa9ff, #6497ff);
    box-shadow: 2px 3px 11px 0px rgba(255, 255, 255, 0.26) inset,-1px -1px 9px 0px rgba(90, 140, 243, 0.67) inset;
    border-radius: 5px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    margin-right: 20px;
}

.com-upload .btn2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 34px;
    border: 1px solid #6E8DFF;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #6E8DFF;
    margin-right: 20px;
}
.com-upload .btn3{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 34px;
    border: 1px solid #FF3E4C;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FF3E4C;
    margin-right: 20px;
}
.btn-box{
    position: relative;
    width: 100%;
    z-index: 10;
}
.notEdit{
    position: absolute;
    width: 100%;
}
.com-upload>>>img{
    pointer-events:unset!important;
}
</style>